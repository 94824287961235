import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../../components/Layout';
import { DefaultVoiceList } from './components';
import { RootState } from '../../store';
import {tmapInterface, AppsflyerInterfaceEvent, AppsflyerInterfaceParam} from "../../libs/tmap-interface";

let firstInited = false;

function VoiceList() {
  const [containerVisible, setContainerVisible] = useState(false);
  const voiceState = useSelector((state: RootState) => state.voice);

  useEffect(() => {
    // 메인 페이지 진입 이벤트 (한번만 호출)
    if(!firstInited) {
      let param;
      if(['female', 'male'].includes(voiceState.selectedGuideType)) {
        param = (voiceState.selectedGuideType === 'female') ? AppsflyerInterfaceParam.DEFAULT_VOICE_FEMALE
          : AppsflyerInterfaceParam.DEFAULT_VOICE_MALE;
      } else param = { af_starvoice_content_id: voiceState.selectedGuideType };
      tmapInterface.recordEvent(AppsflyerInterfaceEvent.INTO_MAIN_VOICE, param);
    }
    Promise.resolve().then(() => setContainerVisible(true));
    firstInited = true;

  }, [voiceState.selectedGuideType]);

  // render
  // if (!inited) return null
  return (
    <>
      <Header />
      <div className="container" style={{ opacity: containerVisible ? 1 : 0 }}>
        <div className="content">

          <section className="voice_type">
            <h1 className="voice_type_title">보이스 선택</h1>

            {/* 기본보이스 */}
            <DefaultVoiceList />
          </section>

        </div>
      </div>
    </>
  );
}

export { VoiceList };
